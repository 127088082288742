import { useStaticQuery, graphql } from 'gatsby';

export const usePageList = () => {
  const {
    allWpPage: { nodes: sitePages },
  } = useStaticQuery(graphql`
    query PAGES {
      allWpPage {
        nodes {
          title
          parentId
          slug
          id
          menuOrder
        }
      }
    }
  `);
  return sitePages;
};
