import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'gatsby';

import '../../styles.scss';
import './MainLayout.scss';
import useImageStaticQuery from '../../hooks/useImageQuery';
import Img from 'gatsby-image';
import MainContext from '../../context/MainContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const MainLayout = ({ location, pages = [], children }) => {
  const [state] = useContext(MainContext);

  const toggleDrawer = (open) => {
    // setMobileMenuOpen(open);
  };
  console.log(location);
  const { pathname } = location;

  // const { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage } = site.siteMetadata;

  useEffect(() => {
    if (!!state && !!state.currentSection) {
    }
  }, [state]);
  return (
    <>
      {pathname !== '/' && (
        <>
          <div className={`topMenuMobile`}>
            <div
              className={`mobileMenuHamburger`}
              role={'button'}
              tabIndex={'0'}
              onKeyPress={(e) => {
                const code = e.keyCode || e.charCode;
                if (code === 13) {
                  toggleDrawer(true);
                }
              }}
              onClick={() => {
                toggleDrawer(true);
              }}
            >
              <div>
                <FontAwesomeIcon icon={faBars} className={`mobileMenuIcon`} />
              </div>
            </div>
          </div>
          <nav className={`topMenu menuVisible`}>
            <div className={'menuItem'} key={`mainMenu_home`}>
              <Link to='/'>Accueil</Link>
            </div>
            {pages
              .filter((page) => !page.parentId)
              .sort((a, b) => {
                if (a.menuOrder < b.menuOrder) {
                  return -1;
                }
                if (a.menuOrder > b.menuOrder) {
                  return 1;
                }
                return 0;
              })
              .map((p) => (
                <div className={'menuItem'} key={`mainMenu_${p.id}`}>
                  <Link to={p.slug}>{p.title}</Link>
                </div>
              ))}
          </nav>
        </>
      )}
      <main className={'pageContent'}>{children}</main>

      {/*<Drawer
        open={mobileMenuOpen}
        onClose={() => {
          toggleDrawer(false);
        }}
      >
        <div className={'menuLogo'}>
          <Link to={'/'}>
            <Img fluid={morscadLogo.childImageSharp.fluid} alt='Website of Omar faleh' />
          </Link>
        </div>

        <nav className={'mobileMenuDrawer'}>
          <div className={'menuItem'}>
            <Link to={'/portfolio'}>Portfolio</Link>
          </div>
          <div className={'menuItem'}>
            <Link to={'/about'}>About</Link>
          </div>
          <div className={'menuItem'}>
            <Link to={'/contact'}>Contact</Link>
          </div>
        </nav>
      </Drawer>*/}
    </>
  );
};
export default MainLayout;
