import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = () => {
  const { site } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          description
          title
        }
      }
    }
  `);
  console.log(site.siteMetadata);
  const { description, title } = site.siteMetadata;

  /*const seo = {
    title: `${defaultTitle}${title && title !== '' ? ` - Portfolio: ${title}` : ''}`,
    description: defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname && pathname !== '' ? `/portfolio/project/${pathname}` : ''}`,
  };*/

  return (
    <Helmet title={title} titleTemplate={title}>
      <meta name='description' content={description} />
      <meta property='og:url' content={'https://souha.tahrani.com'} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
    </Helmet>
  );
};

export default SEO;
